// Import all the tools needed to customize the theme and extract parts of it
// @import '~@angular/material/theming';

@mixin color-theme() {
	$red: mat-palette($mat-red);
	$green: mat-palette($mat-green);
	$orange: mat-palette($mat-orange);

	/*BUTTON*/
	.mat-flat-button.mat-success, .mat-raised-button.mat-success,
	.mat-fab.mat-success, .mat-mini-fab.mat-success,
	.success-box {
		background-color: mat-color($green);
		color: white;
	}

	.mat-flat-button.mat-warn, .mat-raised-button.mat-warn,
	.mat-fab.mat-warn, .mat-mini-fab.mat-warn,
	.warn-button, .warn-box {
		background-color: mat-color($red);
		color: white;
	}

	.mat-flat-button.mat-yellow, .mat-raised-button.mat-yellow,
	.mat-fab.mat-yellow, .mat-mini-fab.mat-yellow,
	.yellow-button, .yellow-box {
		background-color: var(--yellow);
		// color: white;
	}

	/*RADIO BUTTON*/
	.mat-radio-button.mat-success .mat-radio-inner-circle{
		background-color: mat-color($green);
	}
	.mat-radio-button.mat-success.mat-radio-checked .mat-radio-outer-circle{
		border-color: mat-color($green);
	}

	.mat-radio-button.mat-warn .mat-radio-inner-circle{
		background-color: mat-color($red);
	}
	.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle{
		border-color: mat-color($red);
	}

    /*Slide Toggle*/
    .mat-slide-toggle.mat-success.mat-checked .mat-slide-toggle-thumb{
        background-color: mat-color($green);
    }
    .mat-slide-toggle.mat-success.mat-checked .mat-slide-toggle-bar{
        background-color: mat-color($green, 0.5);
    }
}
