// Import all the tools needed to customize the theme and extract parts of it
@import '~@angular/material/theming';

@mixin sidenav-theme($theme) {

	$palette: map-get($theme, primary);

	mat-sidenav-content{
		background-color: #e5e5e5;
	}

	mat-sidenav-content main > h1,
	mat-sidenav-content main > h2,
	mat-sidenav-content main > h3, {
		color: mat-color($palette, 700);
	}
}