/***************************
*          LIST            *
****************************/
mat-list-item.multi-line{
	height: auto;
	padding: 16px 0;
}

mat-list-item.sticky{
	padding: 8px 0 !important;
}

.mat-list-item small,
.mat-nav-item small{
	color: grey;
}

main > mat-list > *,
main > mat-nav-list > *,
main > mat-selection-list > *,
main > form > mat-list > *{
	background: white;
}

.mat-list-item.right-icon .mat-list-item-content{
	justify-content: space-between;
}

.mat-list-item.right-icon .mat-list-text{
	padding-left: 0 !important;
	padding-right: 16px !important;
}

.mat-list-item.right-icon .mat-list-icon{
	width: auto !important;
	order: 2
}

.mat-list-icon{
	text-align: center;
}

mat-nav-list .no-select:hover{
	background:white !important;
}

/***************************
*           TAB            *
****************************/

mat-tab-group .mat-tab-body-wrapper{
	height: inherit;
}

mat-tab-header {
	background: white;
}

/***************************
*          DIALOG          *
****************************/
.is-mobile .app-dialog, .full-screen-dialog{
	max-width: none !important;
}

.is-mobile .app-dialog .mat-dialog-container {
	width: 100vw;
	height: 100vh;
	max-width: none;
	max-height: none;
}

.app-dialog .mat-dialog-container,
.no-padding .mat-dialog-container {
	padding: 0;
}

.mat-button, .mat-icon-button, .mat-flat-button, .mat-stroked-button, .mat-mini-fab{
    &.cdk-program-focused .mat-button-focus-overlay{
        opacity: 0 !important;
    }
}
