/* You can add global styles to this file, and also import other style files */
@import 'lib/core/theming/theme';
@import 'lib/core/material/custom';

@import "../node_modules/angular-calendar/css/angular-calendar.css";

html, body{ height: 100%; height: 100vh; }

body{
	margin: 0;
	min-width: 200px;
	font-family: 'Roboto', 'helvetica';
	color: #404040;
}

main{
	padding-bottom: 1rem;
}

.color-app-background, body{
	background: #e5e5e5;
}

.flex {
	flex: 1;
}

.flexbox{
	display: flex;
}

.row{
	flex-direction: row;
}

.column{
	flex-direction: column;
}

.pale{
    opacity: 0.5;
}
.pale:hover{
    opacity: 1;
}
.pointer, .no-select.pointer, .pointer .no-select{
	cursor: pointer !important;
}

.mat-h2{
	font-weight: 300 !important;
	color: #404040 !important;
	padding: 0 8px;
}
.mat-h3{
	padding: 0 8px;
	margin: 8px 0 0 0;
	color: #404040 !important;
}
.app-list-heading{
	margin: 16px 0 0 0;
}
.mat-drawer-container{
	/* height: 100%; */
	height: calc(100% - 64px) !important; /* Fixes scroll issue with older iOs version (they dont handle flex the right way, so we need to substract height of header) */
}
.mat-drawer-content{
	overflow-x: hidden !important;
}

/* App Sidenav */
.app-sidenav a.active{
	background: mat-color($primary, default) !important;
	color: mat-color($primary, 'default-contrast');
}

/* App Sidenav */
.app-sidenav a.active *{
	color: mat-color($primary, 'default-contrast');
}

/* Expansion Panel */
.mat-expansion-panel-header.mat-expanded{
	background: mat-color($primary, default) !important;
}

.mat-expansion-panel-header.mat-expanded *,
.mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after{
	color: mat-color($primary, 'default-contrast');
}

.align-headers .mat-expansion-panel-header-title,
.align-headers .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.not-mobile main{
	max-width: 1000px;
	margin: auto;
	padding-left: 1rem;
	padding-right: 1rem;
}

.spinner-button {
	position: relative;
}

.spinner-button[disabled]:not(.visible) .mat-button-wrapper{
	color: transparent !important;
}

.spinner-button mat-spinner{
	display: none;
}

.spinner-button[disabled] mat-spinner{
	position: absolute;
	display: block;
	margin: -10px;
	top: 50%;
	left: 50%;
}

.app-fab {
	position: fixed !important;
	bottom: 2rem;
	right: 2rem;
}

.button-wrapper{
	display: flex;
    flex-wrap: wrap;
    gap: 15px 20px;
}
.is-mobile .button-wrapper{
    padding: 0 16px;
}
.is-mobile .button-wrapper .button,
.is-mobile .button-wrapper button{
	flex: 1 1 auto;
}

// @media (max-width: 767px){
// 	.upload_file{
// 		margin-left: 16px;
// 	}
// }

/***************************
*           Text           *
****************************/

.color-text-primary {
	color: mat-color($primary, default) !important;
}
.background-primary {
	background: mat-color($primary, default) !important;
}

.color-text-red {
	color: mat-color($red, default) !important;
}
.background-red {
	background: mat-color($red, default) !important;
}

.color-text-orange {
	color: mat-color($orange, default) !important;
}
.background-orange {
	background: mat-color($orange, default) !important;
}

.color-text-green {
	color: mat-color($green, default) !important;
}
.background-green {
	background: mat-color($green, default) !important;
}

.color-text-grey {
	color: mat-color($secondary, 200) !important;
}

h1 {
	text-align: center;
	color: mat-color($primary, default);
}

.is-mobile h1,
.is-mobile h2,
.is-mobile h3, {
	padding: 0 16px;
}

.toolbar-heading{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.toolbar-heading > *:first-child{
	flex-grow:1;
}

.textbox-padding {
	padding: 1em;
}

.text-center {
	text-align: center !important;
}

.text-top{
	margin-top: 3em;
}

/*----------LINK-----------*/


/***************************
*           ICON           *
****************************/
.color-icon, .color-text-icon-grey{
	color: #666;
}

.secondary-icon{
	font-size: 0.75em;
}

.secondary-icon svg{
	top:70% !important;
	left:65% !important;
	border-radius: 50%;
	padding: 4px;
	width: 10px !important;
	height: 10px  !important;
	opacity: 1;
	background: inherit;
	color: white !important;
}

.ng-fa-icon.fixed-width {
	width: 1em;
	text-align: center;
	display: inline-block;
}


/***************************
*           TAB            *
****************************/
.mat-tab-header{
	border-bottom: 0;
}
.mat-tab-label-container{
	max-width: 1000px;
	margin: 0 auto;
}
.mat-tab-label{
	background: white !important;
}
.tabs-with-icon .mat-tab-label{
	opacity: 0.7;
	color: #444;
	padding: 2px 10px !important;
	height: 52px !important;
}
.tabs-with-icon .mat-tab-label-active{
	opacity: 1;
}
.tabs-with-icon .mat-tab-label .mat-tab-label-content{
	display: flex;
	flex-direction: column;
}
.tabs-with-icon .mat-tab-label fa-icon{
	font-size: 16px;
	color: #666;
	margin-bottom: 4px;
}
.tabs-with-icon.mat-tab-group[mat-stretch-tabs] .mat-tab-label{
	flex-basis: auto !important;
}


/***************************
*         DIALOG           *
****************************/
mat-dialog-container{
	padding-top: 16px !important;
}
form .mat-dialog-content {
	display: flex;
	flex-direction: column;
}

form .mat-dialog-actions{
	justify-content: center;
}
.mat-dialog-content{
	word-break: break-word;
}
.mat-dialog-actions .button,
.mat-dialog-actions button{
	margin-bottom: 8px;
}

/***************************
*          BOXEN           *
****************************/
.small {
	max-width: 500px;
}

.flex-container{
	width: 100%;
	display: flex;
	flex-direction: column;
}

/***************************
*           LIST           *
****************************/

.mat-nav-list .mat-divider,
.mat-selection-list .mat-divider{
	border-color: rgb(224, 224, 224);
}
.mat-list-item fa-icon{
	color: #666;
}

.round-list-item{
	position: relative;
	margin: 0.9em 8px 0.9em 8px;
	width: auto !important;
	background: white;
	touch-action: pan-y;
}

.round-list-item:first-child{
	margin-top:0;
}

.round-list-item:last-child{
	margin-bottom:0;
}

.round-list-item, .round-list-item .mat-list-item-content{
	border-radius: 50px 4px 4px 50px;
	overflow: hidden;
}

.round-list-item .mat-list-item-content{
	padding:0px 0px 0px 8px !important;
}

.round-list-item .mat-list-icon{
	margin: 0 12px;
}

.round-list-item .mat-list-text{
	display:flex;
	height:100%;
	padding: 3px 3px 3px 0 !important;
}

.round-list-item .mat-list-text .mat-line{
	display: flex;
	padding:0px 4px !important;
	height:100%;
	align-items: center;
	border-top: 1px solid #d8d8d8;
}

.round-list-item .mat-list-text .mat-line:first-child{
	border:0;
	font-weight: 600;
	font-size: 13px;
}
.round-list-item .mat-list-text .mat-line:first-child span{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.round-list-item .mat-list-text .mat-line:not(:first-child){
	font-size: 13px;
}

.round-list-item .mat-list-text .weekday{
	font-weight: bold;
}

.round-list-divider{
	border-top-left-radius:20px;
	border-bottom-left-radius:20px;
	margin-left: 1px;
	overflow: hidden;
	cursor: default !important;
}

.round-list-divider:not(.success-box){
	background: mat-color($secondary, default) !important;
}

.round-list-divider * {
	color: white;
}

.round-list-divider small {
	color: #e0e0e0;
}

.mat-list, .mat-selection-list{
	overflow: hidden;
	padding-top: 0 !important;
	margin-top: 8px !important;
}
.mat-selection-list .mat-list-option{
	height: auto !important;
	padding: 14px 0 !important;
}
.mat-list .mat-list-item{
	font-size: 15px !important;
	text-decoration: none;
}
body .mat-list-base .mat-list-item.mat-list-item-with-avatar, .mat-list-base .mat-list-option.mat-list-item-with-avatar{
	height: 48px;
}
body .mat-list-base .mat-list-item.mat-2-line, .mat-list-base .mat-list-option.mat-2-line{
	height: 72px;
}
.mat-list .mat-list-item.head{
	background: #f5f5f5;
	font-weight: bold;
}
.mat-list .mat-list-item.show-all,
.mat-list-base .mat-list-item.mat-list-item-with-avatar.show-all{
	min-height: 30px;
	height: auto;
	padding: 9px 0;
}
.mat-list .mat-list-item.show-all .mat-line{
	white-space: normal;
	overflow: visible;
}
.mat-list .mat-list-item .mat-list-icon{
	font-size: 22px !important;
	width: 22px !important;
	height: 22px !important;
}
.mat-list .mat-list-item .svg-inline--fa{
	vertical-align: 0;
}
.mat-list .mat-list-item .weekday{
	font-weight: bold;
}

.mat-list.small-list .mat-list-item{
	font-size: 14px !important;
	height: 38px !important;
}
.mat-list.small-list .mat-list-item.head{
	font-size: 15px !important;
	height: 43px !important;
}

.mat-list-option:focus,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:focus{
    background: white;
}
.mat-list-option:focus:hover,
.mat-nav-list .mat-list-item:focus:hover,
.mat-action-list .mat-list-item:focus:hover{
	background: rgba(0, 0, 0, 0.04);
}


.mat-nav-list .mat-1-5-line{
	height: 84px !important;
}
.mat-nav-list .mat-line-double,
.mat-nav-list .round-list-item .mat-list-text .mat-line-double{
	height: 140% !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 4px !important;
}
.mat-nav-list .mat-line-double > div{
	display: flex;
	width: 100%;
}

.info_list{
	margin-top: 8px;
	padding: 0 !important;
}
.info_list .item{
	display: flex;
	flex-direction: row;
	justify-self: stretch;
	font-size: 14px;
	padding: 12px 16px;
}

.info_list .item .title{
	flex-shrink:0;
	width: 125px;
	font-weight: 600;
	color: #444;
}

.info_list .item .content{
	max-height: 300px;
	overflow: auto;
}


.is-mobile .mat-list-option:hover,
.is-mobile .mat-list-option.mat-list-item-focus,
.is-mobile .mat-nav-list .mat-list-item:hover,
.is-mobile .mat-nav-list .mat-list-item.mat-list-item-focus{
	background: white;
}

/***************************
*         MAT CARD         *
****************************/
body.is-mobile .mat-card{
	border-radius: 0;
}
.mat-card:not([class*=mat-elevation-z]),
.mat-elevation-z1,
.mat-list,
.mat-tab-header{
	z-index: 2;
	box-shadow: 0 0 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
}
.mat-card-header-text{
	margin: 0 !important;
}
.mat-card-header-text .mat-card-title{
	font-weight: 500;
	font-size: 16px;
	color: #333;
	border-bottom: 1px solid #aaa;
	padding: 0 2px 3px 0;
	margin-bottom: 10px;
}

/***************************
*      MAT ACCORDION       *
****************************/
.mat-expansion-panel{
	margin-top: 8px !important;
	overflow: hidden;
}
.mat-expansion-panel:not([class*=mat-elevation-z]){
	box-shadow: 0 0 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
}
.mat-expansion-panel.mat-expanded{
	background: #D5DFEF;
}
.mat-expansion-panel-body{
	padding: 0 16px 16px !important;
}
.mat-expansion-panel-header{
	padding: 0 16px !important;
}
.mat-expansion-panel-header .mat-list-icon{
	font-size: 22px !important;
	width: 22px !important;
	height: 22px !important;
	padding: 4px;
	color: #666;
	margin-right: 10px;
}
.mat-expansion-panel-header .mat-list-icon .svg-inline--fa{
	vertical-align: 0;
}
.mat-expansion-panel-header-title{
	line-height: 30px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/***************************
*     CONTENT SECTION      *
****************************/
.content-section{
	margin-top: 8px;
}
.content-section.mat-card{
	padding: 16px !important;
}

/***************************
*          TABLE           *
****************************/
body:not(.is-mobile) table.mat-table{
    border-radius: 4px;
    overflow: hidden;
}
table.mat-table tbody tr:last-child td{
	border-bottom-width: 0;
}
table.mat-table tfoot tr:first-child td{
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}

/***************************
*      AUTOCOMPLETE        *
****************************/
.mat-autocomplete-panel {
    max-height: 364px !important;
}


/***************************
*           MISC           *
****************************/

.no-select{
	cursor: default !important;
}

.color-background-secondary {
	background: mat-color($secondary, default) !important;
	color: white;
}

.mat-flat-button.mat-success, .mat-raised-button.mat-success, .mat-fab.mat-success, .mat-mini-fab.mat-success, .success-box{
	background: mat-color($green, default);
}

body:not(.is-mobile) .mat-list,
body:not(.is-mobile) .mat-selection-list,
body:not(.is-mobile) .mat-expansion-panel{
	border-radius: 4px;
}

body:not(.is-mobile) .round-borders{
    border-radius: 4px;
}

.word_wrap{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/***************************
*          COLORS          *
****************************/
:root{
	--dark: #363c46;
	--light: #e4e4e4;

	--primary: #456292;
	--primary-contrast: white;

	--gr: #53a93f;
	--green: #53a93f;

	--li: #966ea9;
	--lila: #966ea9;

	--bl: #5290a9;
	--blue: #5290a9;

	--ye: #f2c658;
	--yellow: #f2c658;

	--re: #b6514f;
	--red: #b6514f;

	--re_dark: #76474B;
	--red_dark: #76474B;

	--or: #F28349;
	--orange: #F28349;

	--cy: #1abc9c;
	--cyan: #1abc9c;
}

.dark_bg{ background: #363c46 !important; color: white; }
.dark_fg { color: #363c46; }

.light_fg { color: #e4e4e4 !important; }
.light_bg { background: #e4e4e4 !important; }

.gr_bg{ background: #53a93f !important; color: white; }
.gr_fg{ color: #53a93f !important; }
.gr_bo{border-color: #53a93f !important;}
.gr_in{ box-shadow: inset 0 0 10px #53a93f !important; }

.li_bg{ background: #966ea9 !important; color: white; }
.li_fg{ color: #966ea9 !important; }
.li_bo{border-color: #966ea9 !important;}

.bl_bg{background: #5290a9 !important;color: white;}
.bl_fg{color: #5290a9 !important;}
.bl_bo{border-color: #5290a9 !important;}

.ye_bg{background: #f2c658 !important;color: #1e2125;}
.ye_fg{color: #f2c658 !important;}
.ye_bo{border-color: #f2c658 !important;}
.ye_in{ box-shadow: inset 0 0 10px #f2c658; }

.re_bg{background: #b6514f !important; color:white}
.re_fg{color: #b6514f !important;}
.re_bo{border-color: #b6514f !important;}
.re_in{ box-shadow: inset 0 0 10px #b6514f; }

.or_bg{background: #F28349 !important; color:white}
.or_fg{color: #F28349 !important;}
.or_bo{border-color: #F28349 !important;}
.or_in{ box-shadow: inset 0 0 10px #F28349; }

/* OLD CYAN: #169098 */
.cy_bg{ background: #1abc9c !important; color: white; }
.cy_fg{ color: #1abc9c !important; }
.cy_bo{ border-color: #1abc9c !important; }
.cy_in{ box-shadow: inset 0 0 10px #1abc9c; }
